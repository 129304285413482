<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
.action-modal {
  z-index: 10;
  position: absolute;
  margin-top: -10px;
  background: white;
}
ul {
  list-style-type: none;
}
.modal-button:hover {
  background-color: rgb(214 208 201) !important;
}
hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.action-list:hover {
  background-color: #d7d9f8;
  transition: 0.7s;
  color: black;
  border-radius: 5%;
}
</style>
<template>
  <div class="card card-custom">
    <div v-if="isQuickBookSyncing" style="position: absolute;
    width: 100%;
    z-index: 1;
    background: rgb(71 71 71 / 20%);
    height: 100%;
    display: flex
;">
      <vDataLoader></vDataLoader>
    </div>
    <div class="card-body">
      <div class="fixed">
        <div class="row">
          <div class="col-md-10 col-sm-10">
            <adv-search
          @search="advSearch"
          :is_date_range="true"
          :is_service="false"
          :is_status="false"
          :is_location_enrollment="false"
          :is_job_report="false"
          :is_admin_sp_location="true"
          :is_admin_sp="currentUser.u.is_admin == 1"
          :is_company="true"
          :is_sp_company="true"
          :is_payment_status="false"
          :reportData="this.excelreports"
          :titleworksheet="this.titleworksheet"
          :is_default_tramist_status="'200'"
          :json_fields="this.json_fields"
          :header="this.header"
          :report_name="'service_report.xls'"
        />
          </div>
          <div class="col-md-2" >
            <div v-if="currentUser.u.is_admin == 1">
              <router-link :to="{ name: 'admin.client.invoice.create' }">
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Invoice
                </div>
              </router-link>
            </div>
            <div v-else>
              <router-link :to="{ name: 'client.client.invoice.create', params: { client_slug: client_slug } }">
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Invoice
                </div>
              </router-link>
            </div>
          </div>
          
          <div
            style="margin-left: -2rem !important"
            class="col-md-1 col-sm-1"
            v-if="reports.length > 0 && !getLoadingData"
          >
            <!-- <download-excel
              class="btn btn-outline-primary ml-1"
              :data="excelreports"
              :worksheet="search.date_from + ' - ' + search.date_to"
              :header="header"
              :fields="json_fields"
              type="xls"
              name="company-report.xls"
            >
              <i class="test-white fa fa-download"></i>
            </download-excel> -->
          </div>
        </div>
        <div class="row mb-2" v-if="true">
          <div class="col-md-10"> </div>
          <div class="col-md-9"></div>
        </div>
      </div>
      <hr v-if="reports.length > 0 && !getLoadingData" />
      <div class="row" v-if="reports.length > 0 && !getLoadingData">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <!-- <th class="text-left">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="selectAll"
                    @change="selectAllApp()"
                  />
                </th> -->
                <th>S.No</th>
                <th class="text-left">Invoice Date</th>
                <th>Invoice No.</th>
                <th>Invoice To</th>
                <th>Invoice By</th>
                <th class="text-center">QuickBooks Synced</th>
                <th class="">Action</th>
                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in reports" :key="index">
                <!-- <td class="text-left">
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    v-model="item.checked"
                    @change="check_app"
                  />
                </td> -->
                <td>
                 {{ ++index }}
                </td>
                <td>
                  {{ new Date(item.created_at).toLocaleDateString() }}
                </td>
                <td>{{ item.invoice_no }}</td>
                <td>{{ item.invoice_to_type=="ISP"?item.sp_name
:item.lc_name }}</td>
                <td>
                  {{ item.created_by_name }}
                </td>
                <td class="text-center">
                  <i v-if="!item.is_quickbooks_synced" class="fa fa-times text-danger"></i>
                  <i v-if="item.is_quickbooks_synced" class="fa fa-check text-success"></i>
                </td>
                <td class="">
                <button
                  class="text-center modal-button modal-box"
                  @click="IndexClick(index)"
                  v-if="item.enrollment_id != ''"
                  style="background-color: rgb(243, 241, 239); padding:0.5rem 0.5rem 0.4rem 0.4rem;"
                >
                  <i class="las la-ellipsis-v modal-box"></i>
                </button>
                <div
                  class="action-modal"
                  v-show="showActionButton === index"
                  style="
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 5%;
                    font-size:0.8rem;
                  "
                >
                  <ul
                    style="
                      border-radius: 5%;
                      margin: 5px;
                      margin-top: 5px;
                      margin-bottom: 5px;
                    "
                  >
                  <!-- <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                      v-if="currentUser.u.is_admin == 0 && !item.is_quickbooks_synced "
                    >
                    <button class="text-dark" style="white-space: nowrap;" @click="syncQuickBooks(item.id)">
                        Sync QuickBooks
                      </button>
                  </li> -->
             
                    <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                    >
                    <router-link :to="{ name: 'admin.client.invoice.detail',params:{id:item.id} }" v-if="currentUser.u.is_admin == 1">
                      <button class="text-dark">
                        View Detail
                      </button>
                    </router-link>
                    <router-link :to="{ name: 'client.client.invoice.detail',params:{client_slug: client_slug, id:item.id} }" v-else>
                      <button class="text-dark">
                        View Detail
                      </button>
                    </router-link>
                    </li> 
                    <hr />
                    <li
                      style="cursor: pointer; padding: 5px"
                      class="action-list"
                    >
                      <button
                        @click="deleteInoiceMaster(item.invoice_master_id)"
                      >
                        Delete
                      </button>
                    </li> 
         

                  </ul>
                </div>
           
              </td>

                
               
              </tr>
      
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" v-if="reports.length < 1 && !getLoadingData && !load">
        <div class="col-md-12 text-center">
          <h4>no report(s) found</h4>
        </div>
      </div>
      <div class="row" v-if="load" style="opacity: 0.8">
        <div class="col-md-12 text-center">
          <h4>Please select the criteria</h4>
        </div>
      </div>

      <div class="row move-below" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  FETCH_CLIENTS,
  FETCH_SERVICES,
  FETCH_COMPANY_WISE_REPORT,
  FETCH_INVOICE_MASTER_LIST,
  DELETE_INVOICE_MASTER,
  FETCH_MASTER_COMPANIES,
  SYNC_QUICKBOOK_MASTER_INVOICE
} from "@/core/services/store/actions.type";
import {
  SET_BREADCRUMB,
  SET_COMPANY_INVOICE_APPOINTMENTS
} from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import AdvSearch from "@/includes/search/AdvSearch";
// import * as PDF417 from "pdf417-generator";
export default {
  components: {
    vDataLoader,
    AdvSearch
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "getClients",
      "getServices",
      "getMasterCompanies",
      "getSearchDate"
    ]),
    // grand_total: function() {
    //   let total = 0;
    //   if (this.reports.length != 0) {
    //     this.reports.forEach(element => {
    //       total += element.amount;
    //     });
    //   }
    //   return total;
    // }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      selectAll: false,
      showActionButton: "",
      company: "",
      company_name: "",
      search: {
        service: "all",
        company: "all",
        date_from: "",
        date_to: "",
        service_status: "all",
        payment_status: "all"
      },
      titleworksheet: "",
      json_fields: {
        Company: "company_name",
        "Applicant Name": "applicant",
        Service: "service",
        Location: "client",
        "Service Status": "service_status",
        "Payment Status": "payment_status",
        "Scheduled Date": {
          callback: value => {
            if (value == "") {
              return `${value.schedule_date}`;
            } else {
              return `${moment(value.enroll_date).format("MM/DD/YYYY")}`;
            }
          }
        },
        Amount: "amount"
      },

      getLoadingData: false,
      columns: [
      {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
        {
          name: "Invoice Number",
          width: "",
          label: "Invoice Number ",
          class: ""
        },
        {
          name: "Invoice To",
          width: "",
          label: "Invoice To",
          class: ""
        },
        {
          name: "Service Name",
          width: "",
          label: "Service ",
          class: ""
        },
        {
          name: "Location name",
          width: "",
          label: "Location ",
          class: ""
        },
        {
          name: "location Name",
          width: "",
          label: "Location Name",
          class: ""
        },
        {
          name: "Service Status",
          width: "",
          label: "Service Status",
          class: ""
        },

        {
          name: "Payment Status",
          width: "",
          label: "Payment Status",
          class: "text-center"
        },
        {
          name: "Invoice No",
          width: "",
          label: "Invoice No",
          class: ""
        },
        {
          name: "Scheduled Date",
          width: "",
          label: "Scheduled Date",
          class: ""
        },
        {
          name: "amount",
          width: "",
          label: "Amount",
          class: "text-right"
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Invoice List",
          route: ""
        },
      ],
      reports: [],
      header: [],
      footer: [],
      load: true,
      excelreports: [],
      client_slug: '',
      isQuickBookSyncing: false,
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    this.$store.commit(SET_COMPANY_INVOICE_APPOINTMENTS, []);
    let serviceOutlet = this.currentUser.u.is_client ? "self" : "all";
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FETCH_SERVICES, serviceOutlet);
    this.$store.dispatch(FETCH_MASTER_COMPANIES);
    document.addEventListener("click", this.handleDocumentClick);
    if(this.getSearchDate!={}&&this.getSearchDate!=''&&this.getSearchDate!=null&&this.currentUser.u.is_admin == 1){
    this.advSearch({header:{},search:this.getSearchDate,footer:{}});
    }else{
      document.getElementById("btn-search").click();
    }
    this.client_slug = this.$route.params.client_slug;
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener("click", this.handleDocumentClick);
  },

  methods: {
    syncQuickBooks(id){
      this.isQuickBookSyncing = true;
      this.$store.dispatch(SYNC_QUICKBOOK_MASTER_INVOICE, id)
      .then(r => {
        this.$toastr.s("Synced Successfully");
        document.getElementById("btn-search").click();
        this.isQuickBookSyncing = false;
      })
      .catch(e => {
        this.isQuickBookSyncing = false;
        this.$toastr.e(e.msg);
      });
    },
    deleteInoiceMaster(invoice_msaterid) {
  
      let message = `You want to delete this invoice - ${invoice_msaterid} \n`
      this.$swal
        .fire({
          title: "Are you sure?",
          text: message,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store
              .dispatch(DELETE_INVOICE_MASTER, invoice_msaterid)
              .then(res => {
              
                  this.$toastr.s(res.msg);
                  this.$store
        .dispatch(FETCH_INVOICE_MASTER_LIST, this.search)
        .then(res => {
          this.reports = [];
          this.getLoadingData = false;

          this.reports = res.data;
    
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
                
              })
              .catch(errs => {
                this.errorMsg = errs.error;
                this.$toastr.e(errs.error);
              });
          }
        });
      },
    handleDocumentClick(event) {
      const hasClass = event.target.classList.contains("modal-box");
      if (!hasClass) {
        // Call the Vue method using this
        this.IndexClick(-1);
      }
    },
    IndexClick(index) {
      if (this.showActionButton === index) {
        this.showActionButton = "";
      } else {
        this.showActionButton = index;
      }
    },
    advSearch(value) {
      this.selectAll = false;
      this.getLoadingData = true;
      this.load = false;
      let { header, search, footer } = value;
      // console.log(value)
      // this.header = header;
      this.titleworksheet = search.date_from + " - " + search.date_to;
      // this.footer = footer;
      this.search = { ...search };
      this.tablereset();
      this.$store
        .dispatch(FETCH_INVOICE_MASTER_LIST, search)
        .then(res => {
          this.reports = [];
          this.getLoadingData = false;

          this.reports = res.data;
    
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },
    tablereset() {
      if (this.search.company != "all") {
        // this.columns = [
        // {
        //   name: "S.No",
        //   width: "",
        //   label: "S.No",
        //   class: ""
        // },
        //   {
        //     name: "Applicant Name",
        //     width: "",
        //     label: "Applicant Name",
        //     class: ""
        //   },
        //   {
        //     name: "Service Name",
        //     width: "",
        //     label: "Service ",
        //     class: ""
        //   },
        //   {
        //     name: "Location name",
        //     width: "",
        //     label: "Location ",
        //     class: ""
        //   },
        //   {
        //     name: "Service Status",
        //     width: "",
        //     label: "Service Status",
        //     class: ""
        //   },

        //   {
        //     name: "Payment Status",
        //     width: "",
        //     label: "Payment Status",
        //     class: "text-center"
        //   },
        //   {
        //     name: "Invoice No",
        //     width: "",
        //     label: "Invoice No",
        //     class: ""
        //   },
        //   {
        //     name: "Scheduled Date",
        //     width: "",
        //     label: "Scheduled Date",
        //     class: ""
        //   },
        //   {
        //     name: "amount",
        //     width: "",
        //     label: "Amount",
        //     class: "text-left"
        //   }
        // ];
        this.json_fields = {
          "Applicant Name": "applicant",
          Service: "service",
          Location: "client",
          "Service Status": "service_status",
          "Payment Status": "payment_status",
          "Scheduled Date": "schedule_date",
          Amount: "amount"
        };
      } else {
        this.json_fields = {
          Company: "company_name",
          "Applicant Name": "applicant",
          Service: "service",
          Location: "client",
          "Service Status": "service_status",
          "Payment Status": "payment_status",
          "Scheduled Date": "schedule_date",
          Amount: "amount"
        };
        // this.columns = [
        //   {
        //     name: "Company name",
        //     width: "",
        //     label: "Company ",
        //     class: ""
        //   },
        //   {
        //     name: "Applicant Name",
        //     width: "",
        //     label: "Applicant Name",
        //     class: ""
        //   },
        //   {
        //     name: "Service Name",
        //     width: "",
        //     label: "Service ",
        //     class: ""
        //   },
        //   {
        //     name: "Location name",
        //     width: "",
        //     label: "Location ",
        //     class: ""
        //   },
        //   {
        //     name: "Service Status",
        //     width: "",
        //     label: "Service Status",
        //     class: ""
        //   },

        //   {
        //     name: "Payment Status",
        //     width: "",
        //     label: "Payment Status",
        //     class: "text-center"
        //   },
        //   {
        //     name: "Invoice No",
        //     width: "",
        //     label: "Invoice No",
        //     class: ""
        //   },
        //   {
        //     name: "Scheduled Date",
        //     width: "",
        //     label: "Scheduled Date",
        //     class: ""
        //   },
        //   {
        //     name: "amount",
        //     width: "",
        //     label: "Amount",
        //     class: "text-left"
        //   }
        // ];
      }
    },
    refreshData() {
      // this.pagination = {
      //   total_records: 0,
      //   total_page: 0,
      //   current_page: 1,
      //   per_page_data: 15,
      //   received_per_page_data: 15
      // };
    },
    selectAllApp() {
      if (this.selectAll) {
        this.show_invoice = true;
        this.reports.filter(app => {
          return (app.checked = true);
        });
      } else {
        this.show_invoice = false;
        this.reports.filter(app => {
          return (app.checked = false);
        });
      }
    },
    check_app() {
      var newarray = this.reports.filter(chart => chart.checked === true);
      if (newarray.length > 0) {
        this.show_invoice = true;
      } else {
        this.selectAll = false;
        this.show_invoice = false;
      }
    },
    // createInvoice() {
    //   this.$store.commit(SET_COMPANY_INVOICE_APPOINTMENTS, []);
    //   if (this.search.company == "all") {
    //     this.$toastr.e("Please select a company");
    //   } else {
    //     var data_array = [];
    //     var newarray = this.reports.filter(chart => chart.checked === true);
    //     newarray.map(data => {
    //       data_array.push(data.id);
    //     });
    //     if (data_array.length > 0) {
    //       window.localStorage.setItem(
    //         "company_invoice_appointment",
    //         data_array
    //       );
    //       this.$store.commit(SET_COMPANY_INVOICE_APPOINTMENTS, data_array);
    //       this.company = this.search.company;
    //       this.$router.push({
    //         name: "company.report.invoicedetail",
    //         params: {
    //           slug: this.$route.params.client_slug,
    //           company_id: this.search.company
    //         }
    //       });
    //     } else {
    //       this.$toastr.e(
    //         "Please select aleast one appointments to generate the report"
    //       );
    //     }
    //   }
    // },
    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      //   this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      //   this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      //   this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      //   this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      //   this.appData();
    }
  }
};
</script>
